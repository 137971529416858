import _taggedTemplateLiteral from "/codebuild/output/src1476890680/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import { colors } from '@guryou/colors';
import { typographyOverrides } from '@guryou/html-theme';
import styled from 'styled-components';
import { LinkButton } from '../buttons';
import propsToCss from '../utils/propsToCss';
const DefaultText = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), props => propsToCss({
  ...typographyOverrides.MuiTypography.body1,
  ...props
}));
const MediumText = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), props => propsToCss({
  ...typographyOverrides.MuiTypography.h3,
  ...props
}));
const LargeText = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), props => propsToCss({
  ...typographyOverrides.MuiTypography.h2,
  ...props
}));
const SmallText = styled.p(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), props => propsToCss({
  ...typographyOverrides.MuiTypography.body2,
  ...props
}));
const SmallGrayText = styled(SmallText)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), colors.grey);
const WarningText = styled(SmallText)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
const LinkText = styled(LinkButton)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n"])), props => propsToCss({
  ...props
}));
export { DefaultText, MediumText, LargeText, SmallText, SmallGrayText, WarningText, LinkText };