import { changeUserAvatarApiCall, createUserAndProviderApiCall, createUserApiCall, deactivateUserAccountApiCall, getAnalyticsApiCall, getCurrentUserApiCall, getCurrentUserApiRequest, inviteAndCreateCollaboratorApiCall, inviteUserApiCall, reactivateUserAccountApiCall, requestReactivateUserAccountApiCall, saveUserRegionApiCall, updateUserProfileApiCall } from '@guryou/api-client-gql';
import { parseAsBool, printInputDate } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';
import { ADD_FRANCESCO_AS_DIRECTOR, OWNER } from '../types';

// Types
export const CURRENT_USER = {
  entity: 'CURRENT_USER',
  action: Action.GET
};
export const UPDATE_USER = {
  entity: 'UPDATE_USER',
  action: Action.UPDATE
};
export const INVITE_USER = {
  entity: 'INVITE_USER',
  action: Action.CREATE
};
export const INVITE_CREATE_COLLABORATOR = {
  entity: 'INVITE_CREATE_COLLABORATOR',
  action: Action.CREATE
};
export const CREATE_USER = {
  entity: 'CREATE_USER',
  action: Action.CREATE
};
export const CREATE_USER_AND_PROVIDER = {
  entity: 'CREATE_USER_AND_PROVIDER',
  action: Action.CREATE
};
export const USER_AVATAR = {
  entity: 'USER_AVATAR',
  action: Action.UPDATE
};
export const USER_REGION = {
  entity: 'USER_REGION',
  action: Action.UPDATE
};
export const DEACTIVATE_USER_ACCOUNT = {
  entity: 'DEACTIVATE_USER_ACCOUNT',
  action: Action.HANDLE
};
export const REQUEST_REACTIVATE_USER_ACCOUNT = {
  entity: 'REQUEST_REACTIVATE_USER_ACCOUNT',
  action: Action.HANDLE
};
export const REACTIVATE_USER_ACCOUNT = {
  entity: 'REACTIVATE_USER_ACCOUNT',
  action: Action.HANDLE
};
export const GY_ANALYTICS = {
  entity: 'GY_ANALYTICS',
  action: Action.HANDLE
};

// Actions
export const getCurrentUserAccount = silent => async dispatch => execute(dispatch, () => getCurrentUserApiCall({
  agreements: ['type', 'decision']
}), CURRENT_USER, false, undefined, silent);
export const getCurrentUserRequest = () => async dispatch => execute(dispatch, () => getCurrentUserApiRequest(), CURRENT_USER);
export const updateUserProfile = request => async dispatch => {
  request.agreements = request.agreements.map(x => ({
    ...x,
    decision: parseAsBool(x.decision)
  }));
  if (request.birthday) {
    request.birthday = "".concat(printInputDate(request.birthday), "T00:00:00.000Z");
  }
  if (!request.birthday) {
    request.birthday = null;
  }
  if (!request.gender) {
    request.gender = 'unknown';
  }
  return execute(dispatch, () => updateUserProfileApiCall({
    request
  }), UPDATE_USER, false, undefined);
};
export const inviteUser = request => async dispatch => {
  const {
    email,
    firstName,
    lastName,
    role
  } = request;
  return execute(dispatch, () => inviteUserApiCall({
    email,
    firstName,
    lastName,
    role
  }), INVITE_USER);
};
export const inviteAndCreateCollaborator = request => async dispatch => {
  const {
    email,
    firstName,
    lastName,
    role,
    resourceId
  } = request;
  return execute(dispatch, () => inviteAndCreateCollaboratorApiCall({
    email,
    firstName,
    lastName,
    role,
    resourceId
  }), INVITE_CREATE_COLLABORATOR);
};
export const createUser = request => async dispatch => {
  const {
    email,
    firstName,
    lastName,
    role,
    agreements
  } = request;
  return execute(dispatch, () => createUserApiCall({
    email,
    firstName,
    lastName,
    role,
    agreements
  }), CREATE_USER);
};
export const createUserAndProvider = request => async dispatch => {
  const {
    email,
    providerName,
    providerType,
    category,
    role,
    agreements
  } = request;
  return execute(dispatch, () => createUserAndProviderApiCall({
    email,
    providerName,
    providerType,
    category,
    role,
    agreements
  }), CREATE_USER);
};
export const changeUserAvatar = fileType => async dispatch => execute(dispatch, () => changeUserAvatarApiCall({
  fileType
}), USER_AVATAR, false);
export const saveUserRegion = region => async dispatch => execute(dispatch, () => saveUserRegionApiCall({
  region
}), USER_REGION, false);
export const deactivateUserAccount = forDelete => async dispatch => execute(dispatch, () => deactivateUserAccountApiCall({
  forDelete
}), DEACTIVATE_USER_ACCOUNT);
export const requestReactivateUserAccount = email => async dispatch => execute(dispatch, () => requestReactivateUserAccountApiCall({
  email
}), REQUEST_REACTIVATE_USER_ACCOUNT);
export const reactivateUserAccount = (email, code) => async dispatch => execute(dispatch, () => reactivateUserAccountApiCall({
  email,
  code
}), REACTIVATE_USER_ACCOUNT);
export const getAnalytics = () => async dispatch => execute(dispatch, () => getAnalyticsApiCall(), GY_ANALYTICS);

// Reset Actions
export const resetUpdateUserState = () => async dispatch => dispatch(resetState(UPDATE_USER));
export const resetCurrentUserState = () => async dispatch => {
  dispatch(resetState(CURRENT_USER));
  dispatch(resetState(OWNER));
};
export const resetUserAvatarState = () => async dispatch => dispatch(resetState(USER_AVATAR));
export const resetCreateUserState = () => async dispatch => dispatch(resetState(CREATE_USER));

// Reducers
export const userReducers = {
  current: basicReducer(CURRENT_USER),
  update: basicReducer(UPDATE_USER),
  invite: basicReducer(INVITE_USER),
  inviteCreateCollaborator: basicReducer(INVITE_CREATE_COLLABORATOR),
  createUser: basicReducer(CREATE_USER),
  createUserAndProvider: basicReducer(CREATE_USER_AND_PROVIDER),
  changeAvatar: basicReducer(USER_AVATAR),
  deactivate: basicReducer(DEACTIVATE_USER_ACCOUNT),
  requestReactivate: basicReducer(REQUEST_REACTIVATE_USER_ACCOUNT),
  reactivate: basicReducer(REACTIVATE_USER_ACCOUNT),
  analytics: basicReducer(GY_ANALYTICS),
  francescoAsDirector: basicReducer(ADD_FRANCESCO_AS_DIRECTOR)
};